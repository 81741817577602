
import React from "react";
import "./Home.css";

export default function NotFound() {
  return (
	  <div className="Home">
	  <div className="lander">
	  <div className="NotFound">
      <h1>Sorry, page not found!</h1>
	  </div>
	  </div>
	  </div>
  );
}